<template>
    <v-container id="rolespermissions" fluid v-if="user && isLoggedIn">
        <v-row class="main ma-0 pa-0" :key="key">
            <v-col v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('roles.create')" cols="12" class="mt-5 pa-0 ma-0">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn block height="60" x-large v-bind="attrs" v-on="on" @click="OpenCreateRole()" text outlined :title="$t('create')"><v-icon large>mdi-plus-box</v-icon></v-btn>
                </template>
                <span>{{$t('create')}}</span>
              </v-tooltip>
            </v-col>
            <v-col  
                cols="12"
                class="my-5 pa-0 ma-0"
                max-width="100%"
                @drop="setRole('')"
                @dragover.prevent 
                @dragenter.prevent>
                <v-sheet outlined>
                    <div class="pa-3 ma-0 custom-light-gray">
                        <v-input class="pa-auto ma-auto align-center" hide-details>
                            {{ $t('notassigned') }}
                            <div class="d-flex" slot="append" right>
                                <v-icon> mdi-account-multiple </v-icon>
                                {{getUsersByRole('').length}}
                            </div>
                        </v-input>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-3 ma-0 white">
                        <draggable :list="getUsersByRole('')" :animation="200" style="min-height:200px" ghost-class="ghost-card" group="users" class="d-flex overflow-auto">
                            <div v-for="(item) in getUsersByRole('')" :key="item.id" @dragstart="setUser(item)">
                                <v-card v-if="item.id==activeuser.id && role_loading" class="ma-2 pa-0" width="350" outlined><v-skeleton-loader type="image"></v-skeleton-loader></v-card>
                                <v-card class="ma-2 pa-2" width="350" outlined v-else>
                                    <v-menu transition="slide-y-transition" content-class="elevation-1" v-if="user.permission.map(i=>i.name).includes('roles.update')"
                                        bottom
                                        min-width="250"
                                        rounded
                                        :close-on-content-click="false"
                                        offset-y>
                                        <template v-slot:activator="{ on: menu, attrs }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on: tooltip }">
                                                    <v-btn style="position:absolute;top:0;right:0" :title="$t('notassigned')" text v-bind="attrs" v-on="{ ...tooltip, ...menu }" class="ma-0 pa-1 text--secondary">
                                                        —<v-icon small>mdi-chevron-down</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{$t('notassigned')}}</span>
                                            </v-tooltip>
                                        </template>
                                        <v-card>
                                            <v-list text active>
                                                <v-list-item-group>
                                                    <v-list-item v-for="(r) in roles" :key="r.id" @click="setUser(item);setRole(r.name)">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ r.name }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-card>
                                    </v-menu>
                                    <v-card-title class="ma-0 pa-2">
                                        <v-avatar
                                            class="ma-auto rounded-circle"
                                            color="custom-accent"
                                            size="88">
                                            <span class="text-uppercase white--text text-h4">{{ item.firstname.charAt(0) }}{{ item.lastname.charAt(0)}}</span>
                                        </v-avatar>
                                    </v-card-title>
                                    <v-card-title class="ma-0 pa-0 text-center">
                                        <span class="ma-auto text-capitalize text--secondary">{{item.fullname}}</span>
                                    </v-card-title>
                                    <v-card-subtitle class="ma-0 pa-0 text-center">
                                        <span class="ma-auto">{{item.email}}</span>
                                    </v-card-subtitle>
                                </v-card>
                            </div>
                        </draggable>
                    </div>
                </v-sheet>
            </v-col>
            <v-col  
                v-for="role in roles"
                cols="12"
                :key="role.id"
                class="my-5 pa-0 ma-0"
                @drop="setRole(role.name)"
                @dragover.prevent 
                @dragenter.prevent>
                <v-sheet outlined>
                    <div class="pa-3 ma-0 custom-light-gray">
                        <v-input class="pa-auto ma-auto align-center" hide-details>
                            {{ role.name }}
                            <div class="d-flex" slot="append" right>
                                <v-icon> mdi-account-multiple </v-icon>
                                {{getUsersByRole(role.name).length}}
                            </div>
                            <v-tooltip slot="prepend" bottom v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('roles.update')">
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenUpdateRole(role)" :title="$t('update')">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                </template>
                                <span>{{$t('update')}}</span>
                            </v-tooltip>
                            <v-tooltip slot="prepend" bottom v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('roles.delete')">
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenDeleteRole(role)" :title="$t('delete')">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                </template>
                                <span>{{$t('delete')}}</span>
                            </v-tooltip>
                        </v-input>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-3 ma-0 white">
                        <draggable :list="getUsersByRole(role.name)" :animation="200" style="min-height:200px" ghost-class="ghost-card" group="users" class="d-flex overflow-auto">
                            <div v-for="(item) in getUsersByRole(role.name)" :key="item.id" @dragstart="setUser(item)">
                                <v-card v-if="item.id==activeuser.id && role_loading" class="ma-2 pa-0" width="350" outlined><v-skeleton-loader type="image"></v-skeleton-loader></v-card>
                                <v-card class="ma-2 pa-2" width="350" outlined v-else>
                                    <v-menu transition="slide-y-transition" content-class="elevation-1" v-if="user.permission.map(i=>i.name).includes('roles.update')"
                                        bottom
                                        min-width="250"
                                        rounded
                                        :close-on-content-click="false"
                                        offset-y>
                                        <template v-slot:activator="{ on: menu, attrs }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on: tooltip }">
                                                    <v-btn style="position:absolute;top:0;right:0" :title="role.name" text v-bind="attrs" v-on="{ ...tooltip, ...menu }" class="ma-0 pa-1 text--secondary">
                                                        {{role.name}} <v-icon small>mdi-chevron-down</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{role.name}}</span>
                                            </v-tooltip>
                                        </template>
                                        <v-card>
                                            <v-list text>
                                                <v-list-item-group mandatory color="primary" v-model="item.roles[0].id">
                                                        <v-list-item @click="setUser(item);setRole('')">
                                                            <v-list-item-content>
                                                                <v-list-item-title>—</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item v-for="(r,i) in roles" :key="i" :value="r.id" @click="setUser(item);setRole(r.name)">
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{ r.name }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-card>
                                    </v-menu>
                                    <v-card-title class="ma-0 pa-2">
                                        <v-avatar
                                            class="ma-auto rounded-circle"
                                            color="custom-accent"
                                            size="88">
                                            <span class="text-uppercase white--text text-h4">{{ item.firstname.charAt(0) }}{{ item.lastname.charAt(0)}}</span>
                                        </v-avatar>
                                    </v-card-title>
                                    <v-card-title class="ma-0 pa-0 text-center">
                                        <span class="ma-auto text-capitalize text--secondary">{{item.fullname}}</span>
                                    </v-card-title>
                                    <v-card-subtitle class="ma-0 pa-0 text-center">
                                        <span class="ma-auto">{{item.email}}</span>
                                    </v-card-subtitle>
                                </v-card>
                            </div>
                        </draggable>
                    </div>
                </v-sheet>
            </v-col>
        </v-row>
 
        <!-- create dialog -->
        <v-dialog v-model="rolecreateoverlay" width="800px">
          <v-card v-if="rolecreateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("additem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <create-role @isValid="validate" :NewItem="activerole"></create-role>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="rolecreateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn dark depressed :title="$t('clear')" @click="ClearRole()">{{$t('clear')}}</v-btn>
              <v-btn class="success" :title="$t('create')" depressed :loading="role_loading" :disabled="!rolecreateformvalid" @click="CreateRole()">
                <span>{{$t('create')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- create dialog -->

        <!-- update dialog -->
        <v-dialog v-model="roleupdateoverlay" width="800px">
          <v-card v-if="roleupdateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("updateitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <update-role @isValid="validate" @UpdatePermissions="UpdatePermissions" :UpdatedItem="activerole" :permissions="permissions"></update-role>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="roleupdateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="success" :title="$t('update')" :loading="role_loading" depressed :disabled="!roleupdateformvalid" @click="UpdateRole()">
                <span>{{$t('update')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- update dialog -->

        <!-- delete dialog -->
        <v-dialog v-model="roledeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="roledeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="role_loading" depressed @click="DeleteRole()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- delete dialog -->

    </v-container>
</template>

<script>
import {mapGetters,mapState,mapActions} from "vuex";
import draggable from "vuedraggable";
import createrole from '../../components/Role/CreateRole.vue';
import updaterole from '../../components/Role/UpdateRole.vue';

export default {
    name: 'RolesPermissions',
    data() {
        return {
            key:1,
            activerole:{},
            activePermissions:[],
            rolecreateoverlay:false,
            roleupdateoverlay:false,
            roledeleteoverlay:false,
            rolecreateformvalid:false,
            roleupdateformvalid:false,
            activeuser:{},
        }
    },
    components: {
        draggable,
        'create-role':createrole,
        'update-role':updaterole,
    },
    created(){},
    computed: {
        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
        ...mapGetters('user',{users:'getUsers'}),
        ...mapGetters('role',{roles:'getRoles'}),
        ...mapGetters('permission',{permissions:'getPermissions'}),
        ...mapState('role',{role_loading:'loading'}),
        ...mapState('permission',{permission_loading:'loading'}),
    },
    watch:{
    },
    methods: {
        validate(context,isValid){
            switch (context) {
                case 'create':
                    this.rolecreateformvalid=isValid;
                    break;

                case 'update':
                    this.roleupdateformvalid=isValid;
                    break;

                default:
                    break;
            }
        },
        UpdatePermissions(updatedPermissions){
            this.activePermissions=updatedPermissions;
        },
        OpenCreateRole() {
            this.rolecreateoverlay = true;
            this.activerole = {};
        },
        OpenUpdateRole(item) {
            this.roleupdateoverlay = true;
            this.activerole = {...item};
        },
        OpenDeleteRole(item) {
            this.roledeleteoverlay = true;
            this.activerole = {...item};
        },
        CreateRole() {
            if(this.rolecreateformvalid && this.user && this.user.permission.map(i=>i.name).includes('roles.create')){
                this.addRole(this.activerole).then(() => {
                    this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
                    this.rolecreateoverlay = false;
                    this.activerole = {};
                },() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                });
            }
        },
        UpdateRole() {
            if(this.roleupdateformvalid && this.user && this.user.permission.map(i=>i.name).includes('roles.update')){
                this.updateRole(this.activerole).then((response) => {
                    this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
                    this.roleupdateoverlay = false;               
                    this.activerole = {};
                    let payload = {role:response.data,permissions:this.activePermissions}
                    this.updateRolePermissions(payload).then((response) => {
                        this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
                        this.roleupdateoverlay = false;               
                        this.activerole = {};
                        this.UpdateRoles(response.data);
                    },() => {
                        this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                    });
                },() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                });
            }
        },
        DeleteRole() {
            if(this.user && this.user.permission.map(i=>i.name).includes('roles.delete')){
                this.deleteRole(this.activerole).then(() => {
                    this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
                    this.roledeleteoverlay = false;
                    this.activerole = {};
                },() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                });
            }
        },
        UpdateRoles(payload){
            const edited_item_index=this.roles.findIndex(
                (item) => item.id === payload.id
            );
            if (edited_item_index !== -1)
                this.roles[edited_item_index] = payload;
            this.key+=1;
        },
        UpdateUsers(payload){
            const edited_item_index=this.users.findIndex(
                (item) => item.id === payload.id
            );
            if (edited_item_index !== -1)
                this.users[edited_item_index] = payload;
            this.key+=1;
        },

        getUsersByRole(role){
            let users;
            if(role==''){
                users = this.users.filter((item)=>{
                    return !item.roles.length;
                });
            }
            else{
                users = this.users.filter((item)=>{
                    return item.roles.length && item.roles[0].name==role;
                });
            }
            return users;
        },
        setUser( user ){
            if(user){
                this.activeuser = user;
            }
        }, 
        setRole( role ){
            if(this.activeuser.roles[0] && this.activeuser.roles[0].name == role || !this.activeuser.roles.length && !role)
            {
                return;
            }
            else if(this.activeuser && role && this.user.permission.map(i=>i.name).includes('roles.update')){
                let payload = {user:this.activeuser,role:role};
                this.assignRoleUser(payload).then((response)=>{
                   this.updateUser(response.data).then((response)=>{
                        this.UpdateUsers(response.data);
                        this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
                        this.activeuser = {};
                    }),() => {
                        this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                    };
                }),() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                };
            }
            else if(this.activeuser && !role && this.user.permission.map(i=>i.name).includes('roles.update')){
                let payload = {user:this.activeuser};
                this.unassignRoleUser(payload).then((response)=>{
                   this.updateUser(response.data).then((response)=>{
                        this.UpdateUsers(response.data);
                        this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
                        this.activeuser = {};
                    }),() => {
                        this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                    };
                }),() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                };
            }
        }, 
        ClearRole() {
            this.activerole = {};
        },

        ...mapActions("role", ["assignRoleUser","unassignRoleUser","addRole","deleteRole","updateRole","updateRolePermissions"]),
        ...mapActions("user", ["updateUser"]),
    },
}
</script>
