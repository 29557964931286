<template> 
  <v-container  class="ma-auto" fluid>
    <v-form ref="form" v-model="valid" v-if="user && isLoggedIn" lazy-validation @submit.prevent="submit">
        <div class="pa-0 ma-0">
             <v-text-field
                v-model="UpdatedItem.name"
                color="custom-accent darken-4"
                :label="$t('name')"
                :placeholder="$t('name')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-card class="pa-0 ma-0 elevation-0">
                <v-row class="pa-0 ma-0">
                    <v-col>
                        <v-card-text>
                            <v-treeview
                                v-model="selectedPermissions"
                                :items="mappedPermissions"
                                item-key="name"
                                selectedPermissions-color="indigo"
                                open-on-click
                                :selectable="UpdatedItem.name!='Admin'"
                                return-object
                                expand-icon="mdi-chevron-down"
                                on-icon="mdi-bookmark"
                                off-icon="mdi-bookmark-outline"
                            ></v-treeview>
                        </v-card-text>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col cols="12" md="6">
                        <v-card-text>
                            <div v-if="selectedPermissions.length === 0" class="title font-weight-light text--secondary pa-4 text-center">{{$t("selectpermissions")}}</div>
                            <v-scroll-x-transition group hide-on-leave>
                                <v-chip
                                v-for="(selection, i) in selectedPermissions"
                                :key="i"
                                dark
                                small
                                class="ma-1"
                                close
                                close-icon="mdi-delete"
                                @click:close="deletePermission(selection)"
                                >{{ selection.name.split('.')[0]+' '+ selection.name.split('.')[1]}}</v-chip>
                            </v-scroll-x-transition>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default({
    
    props: ['UpdatedItem','permissions'],
    data() {
        return {
            valid: false,
            updatedPermissions: [],
            selectedPermissions: [],
            allPermissions: []
        }
    },
    components: {},
    computed: {
        mappedPermissions() {
            const children = Object.entries(
                this.allPermissions
            ).map(([name, children]) => ({ name, children }));
            return children;
        },

        maxTextfieldLength () {
            return 200;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },
        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    created(){
        this.selectedPermissions = [...this.UpdatedItem.permissions];
        this.allPermissions = {...this.permissions};
        this.allPermissions = _.groupBy(this.allPermissions, function(item) {
            return item.name.split(".")[0];
        });
    },
    watch:{
        selectedPermissions() {
            this.validate();
        },
        UpdatedItem: {
            handler(){
                this.validate();
            }, deep: true 
        },
    },
    methods: {
        validate(){
            this.updatedPermissions = this.selectedPermissions.map(permission => permission.id);
            let isValid = this.$refs.form.validate();
            this.$emit('UpdatePermissions',this.updatedPermissions);
            this.$emit('isValid','update',isValid);
        }
    }
})
</script>
<style scoped>
</style>
